import { useEffect } from "react";
import { useTenantContext } from "src/contexts/tenant-context";
import { useSchoolContext } from "src/contexts/school-context";
import { getCache, setCache } from "./utils";
import CircularLoaderWithImage from "./components/loader";
import apiCalls from "src/api";
import { Box } from "@mui/material";
// import PushNotifications from "./components/notifications/push-notification";
import { useSettingsContext } from "./contexts/settings-context";
import { useAuthContext } from "./contexts/auth-context";

const PrivatePagesContainer = ({ children }) => {
  const { setTenantConfig, tenantConfig } = useTenantContext();
  const {
    setGradesSectionsList,
    setNotifications,
    showGloabalLoader,
    setStudentsAllFeeRelatedDetails,
    setStudentsAllFeeRelatedDetailsByYearly,
    setStudentDynamicField,
  } = useSchoolContext();
  const { pullRemoteThemeConfig } = useSettingsContext();
  const { updateUser, user } = useAuthContext();

  const getThisTenantInfo = async () => {
    const resData = await apiCalls.thisTenant();
    if (resData) {
      setTenantConfig(resData);
    }
  };
  const getCurrentUserInfo = async () => {
    const resData = await apiCalls.currentUser();
    if (resData) {
      updateUser(resData);
    }
  };

  const getSchoolGradesSectionsList = async () => {
    const resData = await apiCalls.getGradesSectionsList();
    if (resData) {
      if (user?.role === "teacher") {
        const associatedSections = user?.role_specific_data?.associted_grade_sections;
        if (!associatedSections || associatedSections?.length === 0) {
          setGradesSectionsList(resData);
        } else {
          const filteredSections = resData?.filter((section) =>
            associatedSections?.includes(section.id)
          );
          setGradesSectionsList(filteredSections);
        }
      } else {
        setGradesSectionsList(resData);
      }
    }
  };

  const getAllStudentsAllFeeRelatedDetails = async () => {
    const resData = await apiCalls.getAllStudentsFeeDetails();
    if (resData) {
      setStudentsAllFeeRelatedDetails(resData);
    }
  };

  const getAllStudentsAllFeeRelatedDetailsByYearly = async () => {
    const resData = await apiCalls.getAllStudentsFeeDetailsYearlyType();
    if (resData) {
      setStudentsAllFeeRelatedDetailsByYearly(resData);
    }
  };

  const getAllStudentDynamicField = async () => {
    const resData = await apiCalls.getStudentDynamicField();
    if (resData) {
      setStudentDynamicField(resData);
    }
  };

  const checkAndUploadAttendance = () => {
    const savedAttendances = getCache("savedAttendance", null);
    if (!savedAttendances) {
      setCache("savedAttendance", []);
    }
  };

  const getNotifications = async () => {
    const resData = await apiCalls.getNotifications({ page_size: 20 });
    setNotifications(resData.results);
  };

  useEffect(() => {
    getThisTenantInfo();
    getSchoolGradesSectionsList();
    checkAndUploadAttendance();
    pullRemoteThemeConfig();
    getCurrentUserInfo();
    getNotifications();
    getAllStudentDynamicField();
  }, []);

  useEffect(() => {
    if (tenantConfig?.settings?.tution_fee_type === "by_month") {
      getAllStudentsAllFeeRelatedDetails();
    }
    if (tenantConfig?.settings?.tution_fee_type === "by_yearly") {
      getAllStudentsAllFeeRelatedDetailsByYearly();
    }
  }, [tenantConfig]);

  return (
    <>
      {/* <PushNotifications /> Enable once tested and configurable from dashboard */}
      <CircularLoaderWithImage show={showGloabalLoader} />
      {children}
      <Box sx={{ pb: { md: 0, xs: 5 } }}></Box>
    </>
  );
};

export default PrivatePagesContainer;
