import { paths } from "src/paths";

export const navItems = {
  appMenu: "app-menu",
  dashboard: "dashboard",
  classes: "classes",
  finance: "finance",
  students: "students",
  teachers: "teachers",
  examinations: "examinations",
  attendance: "attendance",
  adminstration: "adminstration",
  announcement: "announcement",
  admissionQuery: "admission-qurey",
  fileManagement: "file-management",
  websiteManagement: "website-management",
  editResult: "edit-result",
};

const teacherAccessibleNavItems = [
  navItems.appMenu,
  navItems.classes,
  navItems.dashboard,
  navItems.attendance,
  navItems.editResult,
];

const teacherAccessiblePages = [
  paths.homeMenu,
  paths.dashboard,
  paths.classes,
  paths.sections,
  paths.attendance,
  paths.profile,
  paths.students,
  paths.viewClass(":id"),
  paths.viewClassSection(":id"),
  paths.takeClassSectionAttendance(":id"),
  paths.editStudent(":id"),
  paths.viewStudent(":id"),
  paths.viewAttendance(":id"),
  paths.attendanceDistributionReport,
  paths.attendanceReport,
  paths.editResult,
];
const studentAccessiblePages = [
  paths.homeMenu,
  paths.parentDashBoard,
  paths.profile,
  paths.profileEdit,
];

const studentAccessibleNavItems = [navItems.appMenu];

const parentAccessiblePages = [
  paths.parentDashBoard,
  paths.studentFee,
  paths.parentNotifications,
  paths.profile,
  paths.profileEdit,
  paths.studentProfileView(":id"),
  paths.studentAttandance,
];

export const canSeeNavItem = (user, item) => {
  if (user.role === "admin" || user.role === "owner" || user.role === "manager") {
    return true;
  } else if (user.role === "teacher" && teacherAccessibleNavItems.includes(item)) {
    return true;
  } else if (user.role === "student" && studentAccessibleNavItems.includes(item)) {
    return true;
  }

  return false;
};

export const canAccessPage = (user, item) => {
  if (user.role === "admin" || user.role === "owner" || user.role === "manager") {
    return true;
  } else if (user.role === "teacher" && teacherAccessiblePages.includes(item)) {
    return true;
  } else if (user.role === "parent" && parentAccessiblePages.includes(item)) {
    return true;
  } else if (user.role === "student" && studentAccessiblePages.includes(item)) {
    return true;
  }
  return false;
};

export const canAccessItem = (user, item) => {
  if (user.role === "admin" || user.role === "owner" || user.role === "manager") {
    return true;
  } else if (user.role === "teacher" && teacherAccessiblePages.includes(item)) {
    return true;
  } else if (user.role === "parent" && parentAccessiblePages.includes(item)) {
    return true;
  } else if (user.role === "student" && studentAccessibleNavItems.includes(item)) {
    return true;
  }
  return false;
};
