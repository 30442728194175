import { tokens } from "../tokens";

export const hi = {
  [tokens.common.languageChanged]: "भाषा बदल गई",
  [tokens.nav.academy]: "अकादमी",
  [tokens.nav.account]: "खाता",
  [tokens.nav.analytics]: "एनालिटिक्स",
  [tokens.nav.overview]: "अवलोकन",
  [tokens.nav.dashboard]: "डैशबोर्ड",
  [tokens.nav.pages]: "पेज",
  [tokens.nav.profile]: "प्रोफ़ाइल",
  [tokens.nav.register]: "पंजीकरण करना",
  [tokens.nav.resetPassword]: "पासवर्ड रीसेट",
  [tokens.nav.socialMedia]: "सोशल मीडिया",
  [tokens.nav.verifyCode]: "कोड सत्यापित करें",
  [tokens.nav.Subjects]: "विषयों",
  [tokens.nav.classes]: "कक्षाएं",
  "nav.classes.num-3": "कक्षा PG",
  "nav.classes.num-2": "कक्षा Nursery",
  "nav.classes.num-1": "कक्षा LKG",
  "nav.classes.num0": "कक्षा UKG",
  "nav.classes.num1": "कक्षा I",
  "nav.classes.num2": "कक्षा II",
  "nav.classes.num3": "कक्षा III",
  "nav.classes.num4": "कक्षा IV",
  "nav.classes.num5": "कक्षा V",
  "nav.classes.num6": "कक्षा VI",
  "nav.classes.num7": "कक्षा VII",
  "nav.classes.num8": "कक्षा VIII",
  "nav.classes.num9": "कक्षा IX",
  "nav.classes.num10": "कक्षा X",
  "nav.classes.num11": "कक्षा XI",
  "nav.classes.num12": "कक्षा XII",
  all: "सभी",
  addNewClass: "नई कक्षा जोड़ें",
  editClass: "कक्षा संपादित करें",
  teachers: "शिक्षक",
  teacher: "अध्यापक",
  students: "छात्र",
  student: "विद्यार्थी",
  attendance: "उपस्थिति",
  finance: "वित्त",
  examination: "परीक्षा",
  edit: "संपादित करें",
  updateResult: "परिणाम अपडेट करें",
  administration: "प्रशासन",
  fileManagement: "फ़ाइल प्रबंधन",
  website: "वेबसाइट",
  subjects: "विषयों",
  workTour: {
    attendance: {
      classSectionFilter:
        "उस कक्षा अनुभाग को देखने के लिए इस फ़िल्टर का उपयोग करें जिसके लिए आप उपस्थिति देखना चाहते हैं।",
      takenByFilter:
        "उपस्थिति रिकॉर्ड को उस शिक्षक द्वारा फ़िल्टर करने के लिए चुनें जिसने उपस्थिति ली।",
      dateRangePicker:
        "तारीख के अनुसार उपस्थिति रिकॉर्ड्स को फ़िल्टर करने के लिए एक तारीख श्रेणी का चयन करें।",
      resetFiltersBtn:
        "सभी फ़िल्टर रीसेट करने और सभी उपस्थिति रिकॉर्ड देखने के लिए यहाँ क्लिक करें।",
      actionButton:
        "इस प्रविष्टि के लिए विस्तृत उपस्थिति रिकॉर्ड देखने के लिए इस बटन पर क्लिक करें।",
    },
    students: {
      addBtn: "छात्र बनाने के लिए यहाँ क्लिक करें।",
      filterGender: "छात्रों की सूची को फ़िल्टर करने के लिए एक लिंग चुनें।",
      filterGradeSection: "खोज को संकीर्ण करने के लिए एक ग्रेड अनुभाग चुनें।",
      filterCaste: "जाति श्रेणी द्वारा छात्रों को फ़िल्टर करें।",
      filterBplStatus: "बीपीएल (गरीबी रेखा से नीचे) स्थिति के आधार पर छात्रों को फ़िल्टर करें।",
      filterStudentType: "छात्र प्रकार के अनुसार फ़िल्टर करें: नियमित या निजी।",
      filterStudentStatus: "छात्र की स्थिति के अनुसार फ़िल्टर करें: अस्थायी या स्थायी।",
      clearFilters: "सभी लागू फ़िल्टर को साफ़ करने और खोज को रीसेट करने के लिए यहाँ क्लिक करें।",
      searchInput:
        "इस खोज पट्टी का उपयोग नाम द्वारा विशिष्ट छात्रों को जल्दी से खोजने के लिए करें।",
      viewIcons: "छात्र को देखने के लिए इन आइकॉन पर क्लिक करें।",
      editIcons: "छात्र को संपादित करने के लिए इन आइकॉन पर क्लिक करें।",
      deleteIcons: "छात्र को हटाने के लिए इन आइकॉन पर क्लिक करें।",
    },
    teachers: {
      addBtn: "नया शिक्षक जोड़ने के लिए यहाँ क्लिक करें।",
      searchInput:
        "नाम द्वारा विशिष्ट शिक्षकों को जल्दी से खोजने के लिए इस खोज पट्टी का उपयोग करें।",
      viewBtn: "शिक्षक की प्रोफ़ाइल और विवरण देखने के लिए इस आइकॉन पर क्लिक करें।",
      editBtn: "शिक्षक की जानकारी संपादित करने के लिए इस बटन का उपयोग करें।",
      payBtn: "शिक्षक का वेतन भुगतान करने के लिए यहाँ क्लिक करें।",
    },
    finance: {
      dateRangeSelector: "वित्त डेटा को फ़िल्टर करने के लिए एक तारीख श्रेणी चुनें।",
      addBtn: "एक नया वित्तीय रिकॉर्ड जोड़ने के लिए यहाँ क्लिक करें।",
    },
    examination: {
      releaseResultButton: "चयनित परीक्षाओं के लिए परिणाम जारी करने के लिए यहाँ क्लिक करें।",
      addExamButton: "नई परीक्षा जोड़ने के लिए यहाँ क्लिक करें।",
      searchInput:
        "नाम द्वारा विशिष्ट छात्रों को जल्दी से खोजने के लिए इस खोज पट्टी का उपयोग करें।",
      examScheduleButton: "परीक्षा अनुसूची देखने के लिए यहाँ क्लिक करें।",
      examResultsButton: "परीक्षा परिणाम देखने के लिए यहाँ क्लिक करें।",
      editExamButton: "परीक्षा विवरण संपादित करने के लिए यहाँ क्लिक करें।",
    },
    subjects: {
      addBtn: "नया विषय जोड़ने के लिए यहाँ क्लिक करें।",
      verificationButton: "विषय जानकारी सत्यापित करने के लिए इस बटन का उपयोग करें।",
      tabs: "प्रासंगिक विषयों को देखने के लिए यहां ग्रेड स्तर बदलें।",
      dataGrid: "इस ग्रिड में चयनित ग्रेड के सभी विषय दिखाए जाते हैं।",
    },
  },
};
