export const paths = {
  checkout: "/checkout",
  contact: "/contact",
  pricing: "/pricing",

  about: "/about",
  index: "/",
  liveResult: "/result-release",
  dashboard: "/dashboard",
  homeMenu: "/menu",
  helpAndSupport: "/help-support",
  parentDashBoard: "/parents/dashboard",
  studentFee: "/parents/student-fee",
  parentNotifications: "/parents/notifications",
  studentAttandance: "/parents/student-attandance",
  studentProfileView: (id) => `/parents/student/${id}/profile`,

  login: "/login",
  signUp: "/sign-up",
  resetPassword: "/reset-password",
  addUser: "/users/new",
  users: "/users",
  viewUser: (id) => `/user/${id}`,
  editUser: (id) => `/user/${id}/edit`,
  permissions: (id) => `/user/${id}/permissions`,

  classes: "/grades",
  addClass: "/grade/add",
  sections: "/grades-sections",
  editClass: (id) => `/grade/${id}/edit`,
  viewClass: (id) => `/grade/${id}`,
  viewClassSection: (id) => `/grades-section/${id}`,

  takeClassSectionAttendance: (id) => `/grades-section/${id}/take-attendance`,
  attendance: "/attendance",
  attendanceReport: "/attendance/report",
  attendanceReportByFeatures: "/attendance/report?tab=features",
  attendanceDistributionReport: "/attendance/distribution-report",
  viewAttendance: (id) => `/attendance/${id}`,

  finance: "/finance",
  addFinance: "/finance/add",
  editFinance: (id) => `/finance/${id}/edit`,
  accounts: "/accounts",
  addAccount: "/accounts/add-account",
  balanceSheet: "/accounts/balance-sheet",
  studentFeeDues: "/students/?tab=fee-dues",
  editAccount: (id) => `/accounts/${id}/edit-account`,
  feeStructure: "/fee-structure",
  feeTags: "/fee-tags",
  addFeeTags: "/fee-tags/add",
  editFeeTags: (id) => `/fee-tags/${id}/edit`,
  addFee: "/fee-structure/add",
  editFee: (id) => `/fee-structure/${id}/edit`,
  addEvent: "/events/add",
  editEvent: (id) => `/events/${id}/edit`,
  previousYearFees: "/students?tab=previous-year-fee",
  addPreviousYearFees: "/students/previous-year-fee/add",
  editPreviousYearFees: (id) => `/students/previous-year-fee/${id}/edit`,
  dailySummary: "/day-to-day-summary",

  courses: "/courses",
  viewEnrollCourse: (id, enrollId) => `/course/${id}/enrollment/${enrollId}`,

  students: "/students",
  addStudent: "/students/add",
  studentTable: "/students/edit-table",
  viewStudent: (id) => `/students/${id}`,
  studentImportSummaries: "/students/import-summaries",
  studentDataFormatter: "/students/data-formatter",
  studentGenerateID: "/students/generate-id",
  studentImportSummary: (id) => `/students/import-summary/${id}`,
  editStudent: (id) => `/students/${id}/edit`,
  studentDistribution: "/students/report",
  studentDraft: "/students/draft",
  addStudentDraft: "/students/draft/add",
  editStudentDraft: (id) => `/students/draft/${id}/edit`,
  studentDynamicField: "/students/dynamic-field",
  addStudentDynamicField: "/students/dynamic-field/add",
  editStudentDynamicFieldForm: (id) => `/students/dynamic-field/${id}/edit`,

  subjects: "/subjects",
  addSubject: "/subjects/add",
  editSubject: (id) => `/subjects/${id}/edit`,

  socialMediaAccounts: "/connect-social-media",
  socialInstagram: "/social/instagram",
  socialFacebook: "/social/facebook",
  socialLinkedIn: "/social/linkedin",

  examinations: `/examinations`,
  addExam: "/examinations/add",
  viewExam: (id) => `/examinations/${id}`,
  editExam: (id) => `/examinations/${id}/edit`,
  addExamSchedule: (id) => `/examinations/${id}/add-schedule`,
  previewExamSchedule: (id) => `/examinations/${id}/preview-exam-schedule`,
  studentResultTable: (id) => `/examinations/${id}/student-result-table`,
  viewExamResults: (id) => `/examinations/${id}/results`,
  viewExamResultsDetail: (id) => `/examinations/results-detail/${id}`,
  editResult: "/examinations/edit-results",
  examResultSearch: "/results",
  questionPaper: `/examinations/question-paper`,
  createQuestionPaper: `/examinations/question-paper?show_model=true&model_content=create_question_paper`,
  editQuestionPaper: (id) => `/examinations/question-paper/${id}/edit`,

  teachers: "/teachers",
  addTeacher: "/teachers/add",
  viewTeacher: (id) => `/teacher/${id}`,
  editTeacher: (id) => `/teacher/${id}/edit`,
  payTeacherSalary: (id) => `/teachers/${id}/pay-salary`,
  assignClassSectionTeacher: "/grades-sections/assign-teacher",
  teacherDraft: "/teacher/draft",
  addTeacherDraft: "/teacher/draft/add",
  editTeacherDraft: (id) => `/teacher/draft/${id}/edit`,
  teacherAttendance: "/teacher/attendance",

  docs: "https://learnplace.in",
  adminstration: "/adminstration",
  // admissionQuery: "/admission-query",
  // publicPost: "/public-post",
  // gallery: "/gallery",
  // announcement: "/announcement",
  fileManagement: "/file-management",
  websiteManagement: "/website-management",

  addPost: "/public-post/add",
  viewPost: (id) => `/public-post/${id}`,
  editPost: (id) => `/public-post/${id}/edit`,
  notice: "/all-announcements",
  addAnnouncement: "/announcement/add",
  editAnnouncement: (id) => `/announcement/${id}/edit`,
  editSliderContent: (id) => `/slider-images/${id}/edit`,
  addAdmission: "admission",
  contantUs: "/contact-us",
  401: "/401",
  404: "/404",
  500: "/500",

  profile: "/profile",
  profileEdit: "/profile/edit",

  actionRequests: "/action-requests",
  activity: "/activities",
  createTenant: "/tenants/create",
  tenants: "/tenants",
  schoolSettings: "/school-settings",
  editSchoolSettings: "/school-settings/edit",
  updateThemeSettings: "/school-settings/update-theme",

  onBoard: "/on-board",
  onBoarding: (id) => `/on-boarding/${id}`,
};
